import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/film/film1.jpeg';
import Img2 from '../../images/film/film2.jpeg';
import Img3 from '../../images/film/film3.jpeg';
import Img4 from '../../images/film/film4.jpeg';
import Img5 from '../../images/film/film5.jpeg';
import Img6 from '../../images/film/film6.jpeg';
import Img7 from '../../images/film/film7.jpeg';


export default () => (
	<div className='editorial-template'>
	<h4>An Ode To the 90s: A Fashion Short Film</h4>
	<p> A short fashion film paying homage to my favourite decade in fashion history. 
		I was always heavily influenced by the intersection of 90’s fashion and hip hop culture. 
		I felt compelled to create a short film that tells 3 stories that will leave you feeling both nostalgic and inspired. 
		Reimagining some of my favourite shows, films and music icons into one video diary. 
		Let’s consider this to be my hypothetical time capsule as a stylist. 
	</p>

	<hr className="editorial-underline"></hr>

	<p> Videographer - Maxime Sanchez </p>
	<p> Stylist - Yasmine Isaya </p>
	<p> Creative director - Yasmine Isaya</p>
	<p> Model - Brigitte Lefebvre-Okankwu </p>
	<p> Model - Ariste Leconte </p>
	<p> Model - Shyra Crenshaw </p>
	<p> Model - Ana Itat </p>
	<p> Model - Tangy Bukokero </p>
	<p> Model - Lucie Atangana </p>
	<p> Model - Nicolas Kessy </p>
	<p> Model - Emmanuel Amangouah</p>

		<div class="project-split">
			<iframe width="820" height="345" 
			src="https://www.youtube.com/embed/KMqG46OaqOY">
			</iframe>
		</div>
		<div class="project-split">
				<img class="project-img" src={Img1}></img>
				<img class="project-img" src={Img2}></img>
				<img class="project-img" src={Img3}></img>
				<img class="project-img" src={Img4}></img>
				<img class="project-img" src={Img5}></img>
				<img class="project-img" src={Img6}></img>
				<img class="project-img" src={Img7}></img>
	</div>
	</div>
);
