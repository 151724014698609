import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";

import Img1 from '../images/editorial/editorial10.jpeg';
import Img2 from '../images/editorial2/editorial9.jpeg';
import Img3 from '../images/editorial3/editorial6.jpg';
import Img4 from '../images/editorial4/editorial3.jpg';
import Img5 from '../images/editorial5/editorial12.png';



export default () => (
	<div className='editorial'>
	<h4>Editorial</h4>
		<div class="editorial-split">

			<a class="editorial-item" href="/editorial/editorial1">
			<div class="editorial-container">
					<img class="portfolio-gallery" src={Img1}></img>
				<div class="project-overlay">
					<div class="editorial-text"> Amplify </div>
				</div>
			</div>
			</a>

			<a class="editorial-item" href="/editorial/editorial2">
			<div class="editorial-container">
					<img class="portfolio-gallery" src={Img2}></img>
				<div class="project-overlay">
					<div class="editorial-text">Facets of Sandra </div>
				</div>
			</div>
			</a>

			<a class="editorial-item" href="/editorial/editorial3">
			<div class="editorial-container">
					<img class="portfolio-gallery" src={Img3}></img>
				<div class="project-overlay">
					<div class="editorial-text"> Bloom </div>
				</div>
			</div>
			</a>
			<a class="editorial-item" href="/editorial/editorial4">
			<div class="editorial-container">
					<img class="portfolio-gallery" src={Img4}></img>
				<div class="project-overlay">
					<div class="editorial-text"> NEXT Models </div>
				</div>
			</div>
			</a>
			<a class="editorial-item" href="/editorial/editorial5">
			<div class="editorial-container">
					<img class="portfolio-gallery" src={Img5}></img>
				<div class="project-overlay">
					<div class="editorial-text"> Mims Models </div>
				</div>
			</div>
			</a>
			</div>
	</div>
);
