import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/editorial5/editorial1.png';
import Img2 from '../../images/editorial5/editorial2.png';
import Img3 from '../../images/editorial5/editorial3.png';
import Img4 from '../../images/editorial5/editorial4.png';
import Img5 from '../../images/editorial5/editorial5.png';
import Img6 from '../../images/editorial5/editorial6.png';
import Img7 from '../../images/editorial5/editorial7.png';
import Img8 from '../../images/editorial5/editorial8.png';
import Img9 from '../../images/editorial5/editorial9.png';
import Img10 from '../../images/editorial5/editorial10.png';
import Img11 from '../../images/editorial5/editorial11.png';
import Img12 from '../../images/editorial5/editorial12.png';
import Img13 from '../../images/editorial5/editorial13.png';
import Img14 from '../../images/editorial5/editorial14.png';
import Img15 from '../../images/editorial5/editorial15.png';
import Img16 from '../../images/editorial5/editorial16.png';


export default () => (
	<div className='editorial-template'>
	<h4> MIM models </h4>
	<p> Creative shoot for MIM models Emma Lang portfolio </p>

	<hr className="editorial-underline"></hr>

	<p> Fashion Stylist - Yasmine Isaya </p>
	<p> Model - Emma Lang </p>
	<p> Photographer - Gabryela Vogua </p>
	<p> Agency: Models International Management </p>

		<div class="project-split">
				<img class="project-img" src={Img1}></img>
				<img class="project-img" src={Img2}></img>
				<img class="project-img" src={Img3}></img>
				<img class="project-img" src={Img4}></img>
				<img class="project-img" src={Img5}></img>``
				<img class="project-img" src={Img6}></img>
				<img class="project-img" src={Img7}></img>
				<img class="project-img" src={Img8}></img>
				<img class="project-img" src={Img9}></img>
				<img class="project-img" src={Img10}></img>
				<img class="project-img" src={Img11}></img>
				<img class="project-img" src={Img12}></img>
				<img class="project-img" src={Img13}></img>
				<img class="project-img" src={Img14}></img>
				<img class="project-img" src={Img15}></img>
				<img class="project-img" src={Img16}></img>
		</div>
	</div>
);
