import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from './components/landing';
import About from './components/about';
import AboutPage from './components/aboutpage';
import Service from './components/service';
import ServicePage from './components/servicepage';
import PortfolioPage from './components/portfoliopage';
import Contact from './components/contact';
import ContactPage from './components/contactpage';
import Footer from './components/footer';
import Navigation from './components/Navigation';
import Section1 from './components/service-section1';
import Section2 from './components/service-section2';
import Section3 from './components/service-section3';
import Section4 from './components/service-section4';
import MainSection5 from './components/main-section5';
import Thankyou from './components/thankyou';
import Testimonials from './components/testimonials';
import Mid1 from './components/mid-section1';
import Process from './components/process';
import Editorial from './components/editorial';
import Campaign from './components/Campaign';
import Film from './components/Film';
import Editorial1 from './components/editorial/editorial1';
import Editorial2 from './components/editorial/editorial2';
import Editorial3 from './components/editorial/editorial3';
import Editorial4 from './components/editorial/editorial4';
import Editorial5 from './components/editorial/editorial5';
import Film1 from './components/film/film1';
import Film2 from './components/film/film2';
import Film3 from './components/film/film3';
import Campaign1 from './components/campaign/campaign1';
import Campaign2 from './components/campaign/campaign2';
import Campaign3 from './components/campaign/campaign3';


function toggleMobileMenu(menu) {
    menu.classList.toggle('open');
}

//this is the links to other pages
ReactDOM.render(

  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<>
  		<Landing />
  		<About />
      <Mid1/>
      <Service />
      <Contact />
  		</>} />
      <Route path="/about" element={<About />} />
      <Route path="/aboutpage" element={<AboutPage />} />
      <Route path="/service" element={<>
  		<ServicePage />
  		<Section1 />
  		<Section2 />
      <Section3 />
      <Section4 />
  		</>} />
      <Route path="/testimonials" element={<Testimonials />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/contactpage" element={<ContactPage />} />
      <Route path="/servicepage" element={<ServicePage />} />
      <Route path="/portfoliopage" element={<PortfolioPage />} />
      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="/Film" element={<Film />} />
      <Route path="/Campaign" element={<Campaign />} />
      <Route path="/editorial" element={<Editorial />} />
      <Route path="/editorial/editorial1" element={<Editorial1 />} />
      <Route path="/editorial/editorial2" element={<Editorial2 />} />
      <Route path="/editorial/editorial3" element={<Editorial3 />} />
      <Route path="/editorial/editorial4" element={<Editorial4 />} />
      <Route path="/editorial/editorial5" element={<Editorial5 />} />
      <Route path="/campaign/campaign1" element={<Campaign1 />} />
      <Route path="/campaign/campaign2" element={<Campaign2 />} />
      <Route path="/campaign/campaign3" element={<Campaign3 />} />
      <Route path="/film/film1" element={<Film1 />} />
      <Route path="/film/film2" element={<Film2 />} />
      <Route path="/film/film3" element={<Film3 />} />    
    </Routes>
    <Footer />
  </Router>,

  document.getElementById("root")
);

serviceWorker.unregister();
